// Composable do anulowania requestów

export enum EAbortError {
  ABORT_ERROR = 'AbortError'
}

export function useAbortController() {
  let abortController: AbortController | null = null;

  function abortRequest() {
    if (abortController === null) return;

    abortController.abort(EAbortError.ABORT_ERROR);
  }

  function registerAbort() {
    abortController = new AbortController();

    return abortController.signal;
  }

  return {
    abortRequest,
    registerAbort
  };
}
